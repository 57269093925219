import { useState } from 'react';

export default function GameTable({ columns = [], rows = [], colors = {}, playerData, setPlayerData }) {
  const handleCellClick = (rowIndex, colIndex) => {
    if (colIndex === 0 || playerData.isDone) return; // Prevent clicking on name column or after submission

    setPlayerData(prev => {
      const key = `${rowIndex}-${colIndex}`;
      const currentValue = prev.answers[key] || 'none';
      const newValue = currentValue === 'none' ? 'cross' : 
                      currentValue === 'cross' ? 'tick' : 'none';

      return {
        ...prev,
        answers: {
          ...prev.answers,
          [key]: newValue
        }
      };
    });
  };

  const getCellContent = (rowIndex, colIndex) => {
    if (colIndex === 0) return null;
    
    const key = `${rowIndex}-${colIndex}`;
    const value = playerData?.answers?.[key];

    if (value === 'cross') {
      return (
        <svg className="w-6 h-6" style={{ color: colors.cross }} viewBox="0 0 24 24">
          <path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        </svg>
      );
    }
    
    if (value === 'tick') {
      return (
        <svg className="w-6 h-6" style={{ color: colors.tick }} viewBox="0 0 24 24">
          <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
        </svg>
      );
    }

    return null;
  };

  if (!columns?.length || !rows?.length) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="text-gray-500">Loading game table...</div>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                style={{ color: colors?.text }}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((_, colIndex) => (
                <td
                  key={colIndex}
                  className={`px-6 py-4 whitespace-nowrap ${
                    colIndex === 0 ? '' : 'cursor-pointer hover:bg-gray-50'
                  }`}
                  onClick={() => handleCellClick(rowIndex, colIndex)}
                >
                  {colIndex === 0 ? (
                    <span className="text-sm font-medium" style={{ color: colors?.text }}>
                      {row}
                    </span>
                  ) : (
                    <div className="flex justify-center">
                      {getCellContent(rowIndex, colIndex)}
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
} 