import { useState } from 'react';

export default function Login({ setIsAdmin, setRoomId }) {
  const [name, setName] = useState('');
  const [serverId, setServerId] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState('');

  const generateRoomId = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  const validateRoomId = (id) => {
    return /^\d{4}$/.test(id);
  };

  const handleCreateRoom = (e) => {
    e.preventDefault();
    setError('');

    if (!name.trim()) {
      setError('Name is required');
      return;
    }

    const roomId = serverId.trim() || generateRoomId();
    if (!validateRoomId(roomId)) {
      setError('Room ID must be a 4-digit number');
      return;
    }

    localStorage.setItem('playerName', name);
    setIsAdmin(true);
    setRoomId(roomId);
  };

  const handleJoinRoom = (e) => {
    e.preventDefault();
    setError('');

    if (!name.trim()) {
      setError('Name is required');
      return;
    }

    if (!validateRoomId(serverId)) {
      setError('Room ID must be a 4-digit number');
      return;
    }

    localStorage.setItem('playerName', name);
    setIsAdmin(false);
    setRoomId(serverId);
  };

  const handleRoomIdChange = (e) => {
    const value = e.target.value;
    if (value === '' || (/^\d+$/.test(value) && value.length <= 4)) {
      setServerId(value);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-2xl shadow-lg">
        <h2 className="text-3xl font-bold text-center text-gray-900">
          Welcome to Comp Game
        </h2>
        <form className="mt-8 space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              id="name"
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
            />
          </div>
          <div>
            <label htmlFor="serverId" className="block text-sm font-medium text-gray-700">
              Room ID {isCreating && "(optional)"}
            </label>
            <input
              id="serverId"
              type="text"
              required={!isCreating}
              value={serverId}
              onChange={handleRoomIdChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
              placeholder={isCreating ? "Leave empty for random ID" : "Enter 4-digit room ID"}
              maxLength={4}
              pattern="\d{4}"
            />
            {error && (
              <p className="mt-2 text-sm text-error">{error}</p>
            )}
          </div>
          <div className="flex flex-col gap-3">
            <button
              type="submit"
              onClick={handleJoinRoom}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Join Room
            </button>
            <button
              type="submit"
              onClick={handleCreateRoom}
              className="w-full flex justify-center py-2 px-4 border-2 border-primary rounded-md shadow-sm text-sm font-medium text-primary bg-white hover:bg-primary/10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Create New Room
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 