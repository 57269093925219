export default function ScoresModal({ players, columns, rows, gameEnded, onClose }) {
  const calculateStats = (players) => {
    return players.map(player => {
      let totalAttempts = 0;
      const problemStats = rows.map((problem, rowIndex) => {
        const attempts = columns.slice(1).map((_, colIndex) => {
          const key = `${rowIndex}-${colIndex + 1}`;
          return player.answers[key] || 'none';
        });
        
        // Count attempts until a send (tick) or all crosses
        let attemptsCount = 0;
        let sent = false;
        for (const attempt of attempts) {
          attemptsCount++;
          if (attempt === 'tick') {
            sent = true;
            break;
          }
          if (attempt === 'cross') continue;
          break; // Stop counting if we hit a 'none'
        }
        
        // Only count as failed if all attempts are crosses
        const failed = attemptsCount === 3 && !sent && attempts.filter(a => a === 'cross').length === 3;
        
        if (failed) totalAttempts += 3;
        else if (sent) totalAttempts += attemptsCount;

        return {
          problem,
          attempts: attemptsCount,
          sent,
          failed
        };
      });

      return {
        ...player,
        problemStats,
        totalAttempts,
        completedAt: player.timestamp
      };
    }).sort((a, b) => a.totalAttempts - b.totalAttempts);
  };

  const sortedPlayers = calculateStats(players);
  const winner = gameEnded ? sortedPlayers[0] : null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">
            {gameEnded ? 'Final Results' : 'Current Progress'}
          </h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">✕</button>
        </div>

        {gameEnded && winner && (
          <div className="bg-success/10 p-4 rounded-lg mb-6">
            <h4 className="text-lg font-bold text-success">
              🎉 Winner: {winner.name} with {winner.totalAttempts} total attempts!
            </h4>
          </div>
        )}

        <div className="space-y-6">
          {sortedPlayers.map((player, index) => (
            <div key={index} className={`border rounded-lg p-4 ${
              gameEnded && index === 0 ? 'border-success' : ''
            }`}>
              <div className="flex justify-between items-center mb-4">
                <div>
                  <span className="font-medium text-lg">{player.name}</span>
                  <span className="ml-4 text-sm text-gray-500">
                    Total Attempts: {player.totalAttempts}
                  </span>
                </div>
                <span className="text-sm text-gray-500">
                  {new Date(player.timestamp).toLocaleString()}
                </span>
              </div>
              
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="text-left px-4 py-2">Problem</th>
                    <th className="text-left px-4 py-2">Attempts</th>
                    <th className="text-left px-4 py-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {player.problemStats.map(({ problem, attempts, sent, failed }, i) => (
                    <tr key={i} className="border-t">
                      <td className="px-4 py-2">{problem}</td>
                      <td className="px-4 py-2">{attempts}</td>
                      <td className="px-4 py-2">
                        <span className={`${
                          failed ? 'text-error' : sent ? 'text-success' : 'text-gray-500'
                        }`}>
                          {failed ? 'Failed' : sent ? `Sent on attempt ${attempts}` : 'In progress'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
          {players.length === 0 && (
            <div className="text-center text-gray-500">
              No attempts recorded yet
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 