import { useState, useRef } from 'react';
import { db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import Papa from 'papaparse';

export default function AdminSetup({ roomId, setGameStarted, setShowSetup, setRoomId }) {
  const [newRoomId, setNewRoomId] = useState(roomId);
  const [columns, setColumns] = useState(['Name', '1', '2', '3']);
  const [rows, setRows] = useState(['']);
  const [colors, setColors] = useState({
    background: '#ffffff',
    text: '#000000',
    cross: '#FF3B30',
    tick: '#34C759'
  });
  const fileInputRef = useRef(null);

  const handleAddRow = () => {
    setRows([...rows, '']);
  };

  const handleRemoveRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          const validRows = results.data
            .map(row => row[0])
            .filter(row => row && row.trim())
            .map(row => row.trim());
          
          if (validRows.length > 0) {
            setRows(validRows);
          } else {
            alert('No valid data found in CSV file');
          }
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          alert('Error parsing CSV file');
        }
      });
    }
  };

  const handleCreateAndStartGame = async () => {
    try {
      if (!newRoomId.trim()) {
        alert('Room ID is required');
        return;
      }
      
      if (rows.some(row => !row.trim())) {
        alert('Please fill in all problems');
        return;
      }

      const roomRef = doc(db, 'rooms', newRoomId);
      
      const roomSnap = await getDoc(roomRef);
      if (roomSnap.exists()) {
        alert('Room ID already exists. Please choose a different one.');
        return;
      }

      const roomData = {
        columns,
        rows,
        colors,
        players: [],
        isActive: true,
        gameStarted: true,
        created: new Date().toISOString(),
        id: newRoomId
      };

      await setDoc(roomRef, roomData);
      console.log('Room created and started with ID:', newRoomId);
      
      setRoomId(newRoomId);
      
      window.history.replaceState(null, '', `?room=${newRoomId}`);
      
      setGameStarted(true);
      setShowSetup(false);
    } catch (error) {
      console.error('Error creating room:', error);
      alert(`Failed to create room: ${error.message}`);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <h2 className="text-2xl font-bold">Room Setup</h2>
      
      <div>
        <label htmlFor="roomId" className="block text-sm font-medium text-gray-700">
          Room ID
        </label>
        <input
          id="roomId"
          type="text"
          value={newRoomId}
          onChange={(e) => setNewRoomId(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border rounded-md"
          placeholder="Enter room ID"
          required
        />
      </div>

      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold">Problems</h3>
          <div className="space-x-4">
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              ref={fileInputRef}
              className="hidden"
            />
            <button
              onClick={() => fileInputRef.current?.click()}
              className="px-4 py-2 text-primary hover:bg-primary/10 rounded-md border border-primary"
            >
              Import CSV
            </button>
            <button
              onClick={handleAddRow}
              className="px-4 py-2 text-primary hover:bg-primary/10 rounded-md"
            >
              Add Problem
            </button>
          </div>
        </div>
        
        {rows.map((row, index) => (
          <div key={index} className="flex gap-4">
            <input
              type="text"
              value={row}
              onChange={(e) => {
                const newRows = [...rows];
                newRows[index] = e.target.value;
                setRows(newRows);
              }}
              className="px-3 py-2 border rounded-md flex-1"
              placeholder={`Problem ${index + 1}`}
            />
            <button
              onClick={() => handleRemoveRow(index)}
              className="px-4 py-2 text-error hover:bg-error/10 rounded-md"
              disabled={rows.length === 1}
            >
              Remove
            </button>
          </div>
        ))}
      </div>

      <button
        onClick={handleCreateAndStartGame}
        className="w-full py-3 bg-primary text-white rounded-lg hover:bg-primary/90"
      >
        Create & Start Game
      </button>
    </div>
  );
} 