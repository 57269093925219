import { useState, useEffect } from 'react';
import Login from './components/Login';
import AdminSetup from './components/AdminSetup';
import Room from './components/Room';

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [roomId, setRoomId] = useState('');
  const [gameStarted, setGameStarted] = useState(false);
  const [showSetup, setShowSetup] = useState(true);

  // Update room ID from URL if present
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const roomParam = params.get('room');
    if (roomParam) {
      setRoomId(roomParam);
      // If room exists in URL, we're joining not creating
      setShowSetup(false);
    }
  }, []);

  // Reset showSetup when creating a new room
  useEffect(() => {
    if (isAdmin && roomId) {
      setShowSetup(true);
    }
  }, [isAdmin, roomId]);

  // Update URL when room ID changes
  useEffect(() => {
    if (roomId) {
      window.history.replaceState(null, '', `?room=${roomId}`);
    }
  }, [roomId]);

  return (
    <div className="min-h-screen bg-gray-50">
      {!roomId ? (
        <Login setIsAdmin={setIsAdmin} setRoomId={setRoomId} />
      ) : isAdmin && showSetup ? (
        <AdminSetup 
          roomId={roomId} 
          setGameStarted={setGameStarted}
          setShowSetup={setShowSetup}
          setRoomId={setRoomId}
        />
      ) : (
        <Room 
          roomId={roomId}
          gameStarted={gameStarted}
          isAdmin={isAdmin}
          setGameStarted={setGameStarted}
        />
      )}
    </div>
  );
}

export default App; 