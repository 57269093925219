import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, onSnapshot, updateDoc, runTransaction, deleteDoc } from 'firebase/firestore';
import GameTable from './GameTable';
import ScoresModal from './ScoresModal';

export default function Room({ roomId, gameStarted: initialGameStarted, isAdmin, setGameStarted }) {
  const [roomData, setRoomData] = useState(null);
  const [playerData, setPlayerData] = useState({
    name: localStorage.getItem('playerName') || '',
    answers: {},
    isDone: false
  });
  const [loading, setLoading] = useState(true);
  const [gameStarted, setLocalGameStarted] = useState(initialGameStarted);
  const [showScores, setShowScores] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [gameEnded, setGameEnded] = useState(false);

  useEffect(() => {
    let unsubscribe;
    
    const setupRoomListener = async () => {
      try {
        const roomRef = doc(db, 'rooms', roomId);
        unsubscribe = onSnapshot(roomRef, 
          async (docSnapshot) => {
            if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              setRoomData(data);
              setLocalGameStarted(data.gameStarted || false);
              
              const allPlayersDone = data.players?.length > 0 && data.players?.every(p => p.isDone);
              const allPlayersClosedModal = data.players?.every(p => p.modalClosed);
              
              if (allPlayersDone) {
                setGameEnded(true);
                if (!showScores) {
                  setShowScores(true);
                }
                
                if (isAdmin && data.endedAt && allPlayersClosedModal) {
                  try {
                    await deleteDoc(doc(db, 'rooms', roomId));
                    console.log('Room deleted successfully');
                  } catch (error) {
                    console.error('Error deleting room:', error);
                  }
                }
              }
              setLoading(false);
            } else {
              setRoomData(null);
              setLoading(false);
            }
          }
        );
      } catch (error) {
        console.error("Error setting up room listener:", error);
        setLoading(false);
      }
    };

    setupRoomListener();
    return () => unsubscribe && unsubscribe();
  }, [roomId, isAdmin, showScores]);

  useEffect(() => {
    if (isAdmin && roomData && !roomData.players?.some(p => p.name === playerData.name)) {
      const roomRef = doc(db, 'rooms', roomId);
      updateDoc(roomRef, {
        players: [...(roomData.players || []), {
          name: playerData.name,
          answers: {},
          isDone: false,
          modalClosed: false,
          timestamp: new Date().toISOString()
        }]
      });
    }
  }, [isAdmin, roomData, playerData.name, roomId]);

  const handleModalClose = async () => {
    try {
      const roomRef = doc(db, 'rooms', roomId);
      await runTransaction(db, async (transaction) => {
        const roomDoc = await transaction.get(roomRef);
        if (!roomDoc.exists()) return;

        const data = roomDoc.data();
        const players = data.players.map(p => 
          p.name === playerData.name 
            ? { ...p, modalClosed: true }
            : p
        );

        transaction.update(roomRef, { players });
      });
      setShowScores(false);
    } catch (error) {
      console.error('Error updating modal state:', error);
    }
  };

  const handleDone = async () => {
    try {
      setSubmitError('');
      if (!playerData.name) {
        setSubmitError('Player name is required');
        return;
      }

      const submission = {
        name: playerData.name,
        answers: playerData.answers,
        isDone: true,
        timestamp: new Date().toISOString()
      };

      await runTransaction(db, async (transaction) => {
        const roomRef = doc(db, 'rooms', roomId);
        const roomDoc = await transaction.get(roomRef);
        
        if (!roomDoc.exists()) {
          throw new Error('Room not found');
        }

        const roomData = roomDoc.data();
        const existingPlayerIndex = roomData.players.findIndex(
          p => p.name === playerData.name
        );

        let newPlayers;
        if (existingPlayerIndex >= 0) {
          newPlayers = [...roomData.players];
          newPlayers[existingPlayerIndex] = submission;
        } else {
          newPlayers = [...roomData.players, submission];
        }

        const allPlayersDone = newPlayers.every(player => player.isDone);
        
        transaction.update(roomRef, { 
          players: newPlayers,
          ...(allPlayersDone ? { 
            endedAt: new Date().toISOString(),
            gameEnded: true 
          } : {})
        });
      });

      setPlayerData(prev => ({ ...prev, isDone: true }));
      setShowScores(true);
    } catch (error) {
      console.error('Error submitting answers:', error);
      setSubmitError(
        error.code === 'resource-exhausted' 
          ? 'Too many updates, please try again in a moment.'
          : 'Failed to submit answers. Please try again.'
      );
    }
  };

  const handleCellUpdate = async (newAnswers) => {
    try {
      const roomRef = doc(db, 'rooms', roomId);
      await runTransaction(db, async (transaction) => {
        const roomDoc = await transaction.get(roomRef);
        if (!roomDoc.exists()) return;

        const roomData = roomDoc.data();
        const existingPlayerIndex = roomData.players.findIndex(
          p => p.name === playerData.name
        );

        const playerUpdate = {
          name: playerData.name,
          answers: newAnswers,
          isDone: false,
          timestamp: new Date().toISOString()
        };

        let newPlayers;
        if (existingPlayerIndex >= 0) {
          newPlayers = [...roomData.players];
          newPlayers[existingPlayerIndex] = playerUpdate;
        } else {
          newPlayers = [...roomData.players, playerUpdate];
        }

        transaction.update(roomRef, { players: newPlayers });
      });
    } catch (error) {
      console.error('Error updating answers:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  if (!roomData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-xl text-error">Room not found</div>
      </div>
    );
  }

  if (!roomData.gameStarted) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center space-y-4">
          <h2 className="text-2xl font-bold">Room: {roomId}</h2>
          {isAdmin ? (
            <div className="space-y-4">
              <p className="text-lg">Room created successfully!</p>
              <button
                onClick={() => {
                  updateDoc(doc(db, 'rooms', roomId), {
                    gameStarted: true
                  });
                }}
                className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90"
              >
                Start Game
              </button>
            </div>
          ) : (
            <p className="text-lg">Waiting for admin to start the game...</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold">Room: {roomId}</h2>
          <p className="text-sm text-gray-600">Playing as: {playerData.name}</p>
        </div>
        {isAdmin && (
          <div className="space-x-4">
            <button
              onClick={() => setShowScores(true)}
              className="px-4 py-2 bg-secondary text-white rounded-lg hover:bg-secondary/90"
            >
              View Scores
            </button>
          </div>
        )}
      </div>
      
      <GameTable
        columns={roomData.columns}
        rows={roomData.rows}
        colors={roomData.colors}
        playerData={playerData}
        setPlayerData={setPlayerData}
        onUpdate={handleCellUpdate}
      />

      {submitError && (
        <div className="text-error text-sm mt-2">{submitError}</div>
      )}

      <button
        onClick={handleDone}
        disabled={playerData.isDone}
        className={`w-full py-3 rounded-lg ${
          playerData.isDone
            ? 'bg-gray-300 cursor-not-allowed'
            : 'bg-primary text-white hover:bg-primary/90'
        }`}
      >
        {playerData.isDone ? 'Submitted' : 'Done'}
      </button>

      {((isAdmin || gameEnded) && showScores && roomData?.players?.length > 0) && (
        <ScoresModal
          players={roomData.players}
          columns={roomData.columns}
          rows={roomData.rows}
          gameEnded={gameEnded}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
} 