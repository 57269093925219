import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC7KzI0c0tTtDwc-9oR2gsSAlqwyfwjZRk",
    authDomain: "foundrycard.firebaseapp.com",
    projectId: "foundrycard",
    storageBucket: "foundrycard.appspot.com",
    messagingSenderId: "990379077865",
    appId: "1:990379077865:web:3bed36ebd2c4027b3e4078"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore with basic settings
const db = getFirestore(app);

export { db }; 